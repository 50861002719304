import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useMobile } from "../../../themes";

export function ProfileFormSectionWithDelete(props: any) {
  const { children, onDelete, ...passProps } = props;
  const isMobile = useMobile();
  return (
    <div
      style={{
        display: isMobile ? "block" : "flex",
        alignItems: "center",
        margin: "16px 0px 0px",
        padding: "16px",
        border: "1px solid #D8D8DD",
        borderRadius: "3px",
      }}
    >
      {isMobile && (
        <IconButton
          color="primary"
          style={{ float: "right", padding: 0 }}
          onClick={onDelete}
        >
          <Delete />
        </IconButton>
      )}
      <Grid container spacing={4} {...passProps}>
        {children}
      </Grid>
      {!isMobile && (
        <IconButton
          color="primary"
          style={{ marginLeft: "16px" }}
          onClick={onDelete}
        >
          <Delete />
        </IconButton>
      )}
    </div>
  );
}
