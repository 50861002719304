import React from "react";
import Select from "../../../../components/form/CustomSelect";
import ValidatedInput from "../../../../components/form/ValidatedInput";
import { GoogleAddressInput } from "../../../../components/react-hook-form";
export const AddressInfo = (props) => {
  const {
    countries,
    getProfileFieldLabel,
    isProfileFieldRequired,
    onChangeProfile,
    onChangeProfileEvt,
    profile: {
      address,
      address: {
        address1 = "",
        address2 = "",
        city = "",
        country = "",
        state = "",
        zip = "",
      } = {},
    } = { address: {} },
    showFormValidation,
  } = props;

  const onChangeCountry = (name, value) => {
    const resetState = address.state && value !== address.country;
    onChangeProfile(name, value, resetState ? { "address.state": "" } : null);
  };

  return (
    <div className="profile-form-section">
      <p className="profile-section-title">Home / permanent address</p>
      <div className="profile-form-grid">
        <ValidatedInput
          className="input-container"
          onChange={(_, val) => onChangeProfile("address.address1", val)}
          name="address.address1"
          value={address1}
          label={getProfileFieldLabel("Street Address", "address.address1")}
          required={isProfileFieldRequired("address.address1")}
          showRequired={showFormValidation}
          input={
            <GoogleAddressInput
              style={{ width: "400px" }}
              variant="standard"
              id="address.address1"
              onSelect={(address) => onChangeProfile("address", address)}
            />
          }
        />
        <ValidatedInput
          autoComplete="address-line2"
          className="input-container"
          label={getProfileFieldLabel("Street Address 2", "address.address2")}
          name="address.address2"
          onChange={onChangeProfileEvt}
          required={isProfileFieldRequired("address.address2")}
          showRequired={showFormValidation}
          value={address2}
        />
        <div className="relative">
          <ValidatedInput
            className="input-container"
            input={
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                options={countries?.map((c) => ({
                  label: c.name,
                  value: c.name,
                }))}
                placeholder="Select Country"
                searchable={true}
              />
            }
            autoComplete="country"
            label={getProfileFieldLabel("Country", "address.country")}
            name="address.country"
            onChange={onChangeCountry}
            required={isProfileFieldRequired("address.country")}
            showRequired={showFormValidation}
            value={country}
          />
        </div>
        <ValidatedInput
          autoComplete="address-level2"
          className="input-container"
          label={getProfileFieldLabel("City", "address.city")}
          name="address.city"
          onChange={onChangeProfileEvt}
          required={isProfileFieldRequired("address.city")}
          showRequired={showFormValidation}
          value={city}
        />
        {country === "USA" || country === "Canada" || country === "Mexico" ? (
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  isClearable={true}
                  options={(
                    (countries.find((c) => c.name === country) || {}).states ||
                    []
                  ).map((s) => ({
                    label: s.name,
                    value: s.name,
                  }))}
                  placeholder="Select State"
                  searchable={true}
                />
              }
              autoComplete="address-level1"
              label={getProfileFieldLabel("State", "address.state")}
              name="address.state"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("address.state")}
              showRequired={showFormValidation}
              value={state}
            />
          </div>
        ) : (
          <ValidatedInput
            autoComplete="address-level1"
            className="input-container"
            name="address.state"
            onChange={onChangeProfileEvt}
            label={getProfileFieldLabel("State", "address.state")}
            required={isProfileFieldRequired("address.state")}
            showRequired={showFormValidation}
            value={state}
          />
        )}
        <ValidatedInput
          autoComplete="postal-code"
          className="input-container"
          name="address.zip"
          onChange={onChangeProfileEvt}
          label={getProfileFieldLabel("Zip", "address.zip")}
          required={isProfileFieldRequired("address.zip")}
          showRequired={showFormValidation}
          value={zip}
        />
      </div>
    </div>
  );
};
export default React.memo(AddressInfo);
