import React from "react";
import { Typography } from "@material-ui/core";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  ProfileFormGridStyled,
  ProfileFormGridItemStyled,
  ProfileFormGridQuarterRowItemStyled,
} from "../../ProfileForm.styles";
import {
  FormFieldWatch,
  FormPhoneField,
  FormTextField,
  FormNumberField,
} from "../../../../../components/react-hook-form";

export const ChabadHouseContactInfo = React.memo(
  /**
   *
   */

  function ChabadHouseContactInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex } = props;

    const chabadHouseFieldsAccessor = `chabadHouses.${chabadHouseIndex}`;

    return (
      <>
        <Typography variant="subtitle2">Contact information</Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "16px", marginBottom: "42px" }}
        >
          <ProfileFormGridQuarterRowItemStyled>
            <FormTextField
              label="Email"
              name={`${chabadHouseFieldsAccessor}.email.value`}
              type="email"
            />
          </ProfileFormGridQuarterRowItemStyled>
          <FormFieldWatch name={"chabadHouse.address.country"}>
            {(country) => (
              <>
                <ProfileFormGridQuarterRowItemStyled xs={9}>
                  <FormPhoneField
                    label="Phone"
                    countryIdFieldName={`${chabadHouseFieldsAccessor}.phone.phoneCountryID`}
                    defaultCountryName={country}
                    name={`${chabadHouseFieldsAccessor}.phone.value`}
                  />
                </ProfileFormGridQuarterRowItemStyled>
                <ProfileFormGridItemStyled sm={1} xs={3}>
                  <FormNumberField
                    label="Ext (opt)"
                    name={`${chabadHouseFieldsAccessor}.phone.phoneExtension`}
                  />
                </ProfileFormGridItemStyled>
              </>
            )}
          </FormFieldWatch>
        </ProfileFormGridStyled>
      </>
    );
  },
);
