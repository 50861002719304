import { ProfileForm } from "./new";

export const ProfilePages = {
  profile: {
    path: "/account",
    title: "My Account",
    type: "PAGE_ACCOUNT",
    view: ProfileForm,
  },
  //TODO: take out when delete old profile
  // personal: {
  //   path: "/account/personal",
  //   title: "My Account: Personal",
  //   type: "PAGE_ACCOUNT_PERSONAL",
  //   view: Profile,
  // },
  // chabadHouse: {
  //   path: "/account/chabadHouse/:chabadHouseId?",
  //   title: "My Account: Chabad House",
  //   type: "PAGE_ACCOUNT_CHABAD_HOUSE",
  //   view: Profile,
  // },
  // school: {
  //   path: "/account/school/:schoolId",
  //   title: "My Account: School",
  //   type: "PAGE_ACCOUNT_SCHOOL",
  //   view: Profile,
  // },
};
export default ProfilePages;

export const ProfileArea = {
  pages: ProfilePages,
};
