import React, { forwardRef } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  styled,
} from "@material-ui/core";
import { Option } from "../../../lib/types";

export type RadioGroupInputProps = {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string | JSX.Element;
  name: string;
  onChange: (name: string, val: any) => void;
  options: Option[];
} & Omit<RadioGroupProps, "onChange">;

const StyledRadio = styled(Radio)(({ theme }) => ({
  "&$checked": {
    color: theme.palette.secondary.main,
  },
}));

export const RadioGroupInput = React.memo(
  forwardRef(
    /**
     *
     */
    function RadioGroupInput(
      {
        disabled,
        error,
        helperText,
        label,
        name,
        onChange,
        options,
        ...passProps
      }: RadioGroupInputProps,
      ref: React.Ref<any> | null,
    ) {
      return (
        <>
          <FormControl ref={ref}>
            {label && <FormLabel error={error}>{label}</FormLabel>}
            <RadioGroup
              name={name}
              onChange={(e) => onChange(name, e.target.value)}
              {...passProps}
            >
              {options.map((option) => (
                <FormControlLabel
                  control={<StyledRadio />}
                  disabled={disabled}
                  key={option.id}
                  label={option.name}
                  value={option.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </>
      );
    },
  ),
);
