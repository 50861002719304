import React from "react";
import { Navigation, PageURL, withAppInsights } from "../../lib";

import Loader from "../../components/Loader";
import ProgramBreadcrumbsHeader from "../../components/ProgramBreadcrumbsHeader";
import MyTrip from "./MyTrip";
import TripEnrollmentFormContainer from "./TripEnrollmentFormContainer";
import EnrollmentClosed from "./enrollmentStates/EnrollmentClosed";
import EnrollmentCompleted from "./enrollmentStates/EnrollmentCompleted";

const { REACT_APP_COCI_STUDENT_PORTAL_DOMAIN } = process.env;

class Trip extends React.Component {
  state = {
    enrollmentSubmitted: false,
    tabContentShowing: "",
  };

  async componentDidMount() {
    const {
      actions: {
        getAccountInfo,
        getProgramRoutes,
        getShliachTrip,
        getTripEvent,
        getTripEnrollment,
      },
      shliachID,
    } = this.props;

    this.setState({ loading: true });

    let {
      location: { pathname },
      params: { programScheduleID },
    } = this.props.pageRoute;

    if (!programScheduleID) {
      await getProgramRoutes();
      const routes = this.props.programs.routes.filter(
        (route) => route.baseUrl.indexOf("trips") >= 0,
      );

      const routeToUse =
        routes.find(
          (route) => route.types && route.types.indexOf("Current") >= 0,
        ) ||
        routes.find(
          (route) => route.types && route.types.indexOf("Managed") >= 0,
        );
      if (routeToUse) {
        programScheduleID = routeToUse.scheduleID;
        Navigation.redirect(`${pathname}/${programScheduleID}`);
      }
      return;
    }

    await getAccountInfo(shliachID);

    await getShliachTrip(shliachID, programScheduleID);

    const shliachTripInfo = this.props.trip.shliach?.filter(
      (trip) => trip.isSelected === true,
    )[0];

    await getTripEvent(programScheduleID);
    await getTripEnrollment(
      shliachTripInfo?.enrollmentID || 0,
      this.props.trip.event.id,
    );

    this.setState({ loading: false });
  }

  setEnrollmentSubmitted = (submitAttempted) => {
    const enrollmentSubmitted =
      this.props.trip.enrollment.success === true && submitAttempted;
    this.setState({ enrollmentSubmitted });
  };

  render() {
    const {
      actions: { getAccountInfo, getHistoricTripEnrollments, getTripSchedule },
      auth,
      account,
      pageRoute,
      shliachID,
      trip,
      trip: {
        event,
        enrollment,
        enrollment: { errorStatus } = {},
        errorMessage,
        success,
      } = {},
    } = this.props;
    const { enrollmentSubmitted, loading } = this.state;

    const studentRegistrationPreviewUrl = !enrollment?.id
      ? null
      : PageURL.to(
          `${REACT_APP_COCI_STUDENT_PORTAL_DOMAIN}${enrollment.chabadHouseSlug}/trips/${event.type}/${event.programScheduleID}`,
          null,
          {
            exp: auth.expiration,
            shliach: auth.shliachID,
            token: auth.token,
          },
        );

    return (
      <div
        className={`trip-page page ${
          enrollment && !enrollment.id ? "trip-page-background" : ""
        }`}
      >
        {event && enrollment && loading === false ? (
          <>
            <div className="container" style={{ paddingTop: "16px" }}>
              <ProgramBreadcrumbsHeader scheduleId={event.programScheduleID} />
            </div>
            {success ? (
              !enrollment.id ? (
                (!enrollment.isOpenForEnrollment || event.isTravelTrip) &&
                enrollment.success !== false ? (
                  <EnrollmentClosed
                    closedDescription={
                      enrollment.isOpenForEnrollment ? "not available" : ""
                    }
                    getHistoricTripEnrollments={getHistoricTripEnrollments}
                    getTripSchedule={getTripSchedule}
                    pageRoute={pageRoute}
                    programScheduleName={event.programScheduleName}
                    programScheduleID={event.programScheduleID}
                    shliachID={shliachID}
                    trip={trip}
                  />
                ) : (
                  <TripEnrollmentFormContainer
                    {...this.props}
                    account={account}
                    event={event}
                    setEnrollmentSubmitted={this.setEnrollmentSubmitted}
                  />
                )
              ) : !enrollmentSubmitted ? (
                <MyTrip
                  {...this.props}
                  studentRegistrationPreviewUrl={studentRegistrationPreviewUrl}
                  getAccountInfo={getAccountInfo}
                />
              ) : (
                <EnrollmentCompleted
                  pageRoute={pageRoute}
                  programScheduleID={event.programScheduleID}
                  programScheduleName={event.programScheduleName}
                  setEnrollmentSubmitted={this.setEnrollmentSubmitted}
                  studentRegistrationPreviewUrl={studentRegistrationPreviewUrl}
                />
              )
            ) : (
              <div className="text-center">
                <img
                  className="mt-48"
                  src={`/images/${
                    errorMessage === "Event404" ? "not_available" : "error"
                  }.svg`}
                  alt="error-robot"
                  height="320"
                />
                <p
                  className="text-center error-message mt-24"
                  style={{ position: "relative" }}
                >
                  {errorMessage
                    ? errorMessage === "Event404"
                      ? "No additional details available for this schedule"
                      : errorMessage
                    : errorStatus && errorStatus === 403
                    ? "You are not authorized to enroll in this trip schedule"
                    : "Our server is being stubborn. Please try again."}
                </p>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default withAppInsights(Trip);
