import React from "react";
import {
  Box,
  Grid,
  GridProps,
  Typography,
  styled,
  Button,
} from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";
import { profileMenuItemClassName } from "./profileMenu";

const flexAlignCenter = {
  display: "flex",
  alignItems: "center",
};

export const ProfileFormContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  [`${theme.breakpoints.down("sm")}`]: {
    display: "block",
  },
}));

export const ProfileFormMenuStyled = styled(Box)(({ theme }) => ({
  minWidth: "325px",
  padding: "24px 16px",
  "& > *": {
    margin: "8px",
  },
  [`& .${profileMenuItemClassName}.active`]: {
    backgroundColor: theme.palette.primary.light,
    [`${theme.breakpoints.down("sm")}`]: {
      color: theme.palette.primary.dark,
      borderBottom: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: "0px",
      backgroundColor: theme.palette.common.white,
    },
  },
  [`${theme.breakpoints.down("sm")}`]: {
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.common.white,
    whiteSpace: "nowrap",
    overflow: "auto",
    padding: "16px",
  },
}));

export const ProfileFormInfoIcon = styled(InfoRounded)(() => ({
  height: "16px",
  width: "16px",
  color: "#7C71F9",
  marginLeft: "6px",
}));

export const ProfileMenuItemButtonStyled = styled(Button)(({ theme }) => ({
  display: "block",
  textAlign: "left",
  marginRight: "16px",
  [`${theme.breakpoints.down("sm")}`]: {
    padding: "8px 0",
    minWidth: "fit-content",
  },
}));

export const ProfileFormContentStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "1100",
  padding: "24px",
  "& > div:first-of-type": {
    height: "calc(100vh - 120px)", // subtract app header height (72px) and container padding (48px)
    overflow: "hidden auto",
    paddingRight: "24px",
    marginRight: "16px",
  },
  "& > div:last-of-type": {
    minWidth: "155px",
    "& button": { width: "100%" },
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
  },
  "& .MuiSelect-root": {
    height: "0px",
    ...flexAlignCenter,
  },
  // input label styles
  "& .MuiInputLabel-root": {
    fontSize: "14px",
    color: theme.palette.text.secondary,
    marginBottom: "8px",
    ...flexAlignCenter,
  },
  [`${theme.breakpoints.down("sm")}`]: {
    display: "flex",
    padding: "0px 0px 24px 24px",
    "& > div:first-of-type": {
      height: "calc(100vh - 172px)", // subtract app header height (56px) and container padding (24px) and 90px for mobile menu header
    },
  },
}));

export const ProfileFormSaveStyled = styled(Box)(({ theme }) => ({
  [`${theme.breakpoints.down("sm")}`]: {
    position: "fixed",
    bottom: "0px",
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    padding: "16px",
    width: "calc(100% - 50px)",
  },
}));

export const ErrorMessageStyled = styled(Typography)(({ theme }) => ({
  backgroundColor: `${theme.palette.error.main}1A`,
  color: theme.palette.error.main,
  fontSize: "10px",
  whiteSpace: "pre-wrap",
  padding: "8px",
  marginTop: "16px",
}));

export const LoadingContainerStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 72px)",
}));

export const ProfileFormHeaderStyled = styled(Box)(({ theme }) => ({
  marginBottom: "24px",
  "& h4": {
    marginBottom: "16px",
  },
  "& > div:last-of-type": {
    display: "flex",
    "& svg": {
      color: theme.palette.text.secondary,
      marginRight: "6px",
      height: "20px",
      width: "20px",
    },
  },
}));

export const ProfileFormReadOnlyFieldStyled = styled(Typography)(() => ({
  paddingTop: "8px",
}));

export const ProfileFormTypographyFlexStyled = styled(Typography)(
  () => flexAlignCenter,
);

export const ProfileFormGridItemFlexStyled = styled(ProfileFormGridItemStyled)(
  () => flexAlignCenter,
);

export function ProfileFormGridStyled(props: GridProps) {
  const { children, ...passProps } = props;
  return (
    <Grid container spacing={4} {...passProps}>
      {children}
    </Grid>
  );
}

export function ProfileFormGridOutlinedStyled(props: GridProps) {
  return (
    <ProfileFormGridStyled
      {...props}
      style={{
        margin: "16px 0px 0px",
        padding: "16px",
        border: "1px solid #D8D8DD",
        borderRadius: "3px",
      }}
    />
  );
}

export function ProfileFormGridItemStyled(props: GridProps) {
  const { children, ...passProps } = props;
  return (
    <Grid item xs={12} sm={4} {...passProps}>
      {children}
    </Grid>
  );
}

export function ProfileFormGridFullRowItemStyled(props: GridProps) {
  const { children, ...passProps } = props;
  return (
    <Grid item xs={12} sm={12} {...passProps}>
      {children}
    </Grid>
  );
}

export function ProfileFormGridHalfRowItemStyled(props: GridProps) {
  const { children, ...passProps } = props;
  return (
    <Grid item xs={12} sm={6} {...passProps}>
      {children}
    </Grid>
  );
}

export function ProfileFormGridQuarterRowItemStyled(props: GridProps) {
  const { children, ...passProps } = props;
  return (
    <Grid item xs={12} sm={3} {...passProps}>
      {children}
    </Grid>
  );
}

export const FormUploadContainerStyled = styled(Box)(({ theme }) => ({
  ...flexAlignCenter,
  width: "fit-content",
  cursor: "pointer",
  color: theme.palette.primary.main,
  textTransform: "uppercase",
  "&:hover": {
    opacity: ".8",
  },
  "& .MuiAvatar-root": {
    height: "80px",
    width: "80px",
    marginRight: "24px",
  },
}));
