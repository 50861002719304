import { UseFormReturn } from "react-hook-form";
import { Dispatch, AnyAction } from "redux";
import { IntersectionOptions } from "react-intersection-observer";
import {
  dateOnlySchema,
  getValidatedPhoneSchema,
  isNotValidDOB,
  nullBooleanSchema,
  nullNumberSchema,
} from "../../../lib";
import { Address, FileForUpload } from "../../../lib/types";
import * as yup from "yup";
// @ts-ignore
import clonedeep from "lodash.clonedeep";
import { SysActions } from "../../../state/sys/actions";

// #region Constants

export const Genders = {
  Unknown: "Unknown",
  Male: "Male",
  Female: "Female",
};

export const ContactMethodTypes = {
  Email: "Email",
  HomeNumber: "HomeNumber",
  MobileNumber: "MobileNumber",
  WorkNumber: "WorkNumber",
};

export const SocialAccountTypes = {
  Facebook: "Facebook",
  Instagram: "Instagram",
  X: "Twitter",
};

export const ProgramLevels = {
  Undergrad: "Undergrad",
  Grad: "Grad",
};

// #endregion

// #region Types
export interface ProfileFormSectionProps {
  form: UseFormReturn<ShliachProfile>;
  initialFormValues: ShliachProfile;
  intersectionOptions: IntersectionOptions;
}

interface ContactMethod {
  type: string | null;
  value: string | null;
  isPrimary: boolean;
  phoneExtension?: string | null;
}

interface ShliachOutreach {
  demographics: string[] | null;
  category: string;
  shliachID: number;
}

interface ShliachPerson {
  beforeNightfall: boolean | null;
  contactMethods: ContactMethod[];
  dob: string | null;
  familyID: number;
  firstName: string | null;
  gender: string | null;
  hebrewDOB: string | null;
  hebrewName: string | null;
  id: number;
  lastName: string | null;
  nickname: string | null;
  outreach: ShliachOutreach | null;
  profileImageURL: string | FileForUpload | null;
  shliachID: number;
  title: string | null;
  titleDisplay: string | null;
}

interface ShliachFamily {
  address: (Address & { hideFromPublic: boolean }) | null;
  children:
    | {
        id?: number;
        person: {
          beforeNightfall: boolean;
          dob: string | null;
          firstName: string | null;
          gender: string | null;
          hebrewDOB: string | null;
          hebrewName: string | null;
          id?: number;
          lastName: string | null;
        };
        schoolType: string | null;
        tShirtSize: string | null;
      }[]
    | null;
  id: number;
}

export interface ChabadHouseCampus {
  campusID: number;
  campusName: string;
  chabadHouseID: number;
  chabadHouseName: string;
  distance: string | null;
  isFullTime: boolean;
  isPrimary: boolean;
}

export interface ChabadHousePersonnel {
  chabadHouseID?: number;
  chabadHousePersonnelID?: number;
  email: string | null;
  expirationDate: string | null;
  firstName: string | null;
  id?: number | null;
  isStudent: boolean;
  lastName: string | null;
  personID?: number | null;
  personnelID?: number;
  position: string | null;
  positionDisplay: string | null;
  studentID?: number | null;
}

export interface PaymentProviderAccount {
  category: string | null;
  defaultCurrency: string | null;
  id?: number;
  isTestMode: boolean;
  paymentProviderAccountID: string | null;
  paymentProviderAccountName: string | null;
  providerName: string | null;
  publicKey: string | null;
  secretKey: string | null;
}

export interface SocialMediaAcount {
  chabadHouseID?: number;
  optOut: boolean;
  type: string | null;
  url: string | null;
}

export interface ChabadHouseAddress extends Address {
  hideFromPublic: boolean;
}

export interface ChabadHouse {
  address: ChabadHouseAddress | null;
  buildingImageURL: string | FileForUpload | null;
  campuses: ChabadHouseCampus[];
  email: ContactMethod | null;
  phone: ContactMethod | null;
  donateURL: string | null;
  id: number;
  isRegistered501: boolean | null;
  legalName: string | null;
  logoURL: string | FileForUpload | null;
  name: string | null;
  paymentProviderAccounts: PaymentProviderAccount[];
  personnel: ChabadHousePersonnel[];
  region: { id: number; name: string } | null;
  regionID: number | null;
  slug: string | null;
  socialMediaAccounts: SocialMediaAcount[];
  taxID: string | null;
  timezoneID: string | null;
  websiteURL: string | null;
}

export interface CampusStudentResource {
  id?: number;
  studentResourceID: number;
  provider: string;
}

interface Campus {
  annualTuition: number | null;
  graduatePopulation: number | null;
  id?: number;
  isActive: boolean;
  isInstitutionControlPublic: boolean;
  jewishUndergradPopulation: number | null;
  jewishGraduatePopulation: number | null;
  name: string | null;
  nickname: string | null;
  nicknameOptOut: boolean | null;
  prestige: string | null;
  programLevels: string[] | null;
  schoolCalendarType: string | null;
  shliachChaplainStatus: string | null;
  shluchaChaplainStatus: string | null;
  chabadStudentGroupStatus: string | null;
  studentGroups: string[];
  studentResidencyType: string | null;
  studentResources: CampusStudentResource[];
  studyAreas: string[] | null;
  undergradPopulation: number | null;
}

export interface ShliachProfile {
  campuses: Campus[];
  chabadHouses: ChabadHouse[];
  family: ShliachFamily | null;
  id: number;
  person: ShliachPerson | null;
  spouse: ShliachPerson | null;
}

// #endregion

// #region Validation
//TODO backend models may have some validation requirement like max length so ideally should implement those rules here also

export const requiredMessage = "is required";

const addressValidationSchema = {
  address1: yup.string().required(`Address ${requiredMessage}`),
  address2: yup.string().nullable(),
  country: yup.string().required(`Country ${requiredMessage}`),
  city: yup.string().required(`City ${requiredMessage}`),
  state: yup.string().when("country", {
    is: (country: string) => country === "USA",
    then: (schema) => schema.required(`State ${requiredMessage}`),
    otherwise: (schema) => schema.nullable(),
  }),
  zip: yup.string().when("country", {
    is: (country: string) => country === "USA",
    then: (schema) => schema.required(`Zip ${requiredMessage}`),
    otherwise: (schema) => schema.nullable(),
  }),
};

// #region Personal
const familyValidationSchema = yup.object({
  address: yup.object(addressValidationSchema).required(),
  children: yup
    .array(
      yup.object({
        person: yup.object({
          firstName: yup.string().required(`First name ${requiredMessage}`),
          gender: yup
            .string()
            .required(`Gender ${requiredMessage}`)
            .notOneOf([Genders.Unknown], `Gender ${requiredMessage}`),
          hebrewName: yup.string().required(`Hebrew name ${requiredMessage}`),
          dob: dateOnlySchema
            .required(`Birthday ${requiredMessage}`)
            .test(
              "valid-birthday",
              "Birthday is invalid",
              (value) => !value || !isNotValidDOB(value),
            ),
          beforeNightfall: nullBooleanSchema.required(
            `Nightfall/Sunset ${requiredMessage}`,
          ),
        }),
        schoolType: yup.string().required(`School type ${requiredMessage}`),
        tShirtSize: yup.string().nullable(),
      }),
    )
    .nullable(),
});

const outreachValidationSchema = yup.object({
  demographics: yup.array().min(1, `Demographics ${requiredMessage}`),
});

const contactMethodValidationSchema = yup.object({
  isPrimary: nullBooleanSchema,
  type: yup.string().required(`Type ${requiredMessage}`),
  value: yup
    .string()
    .required(`Contact ${requiredMessage}`)
    .when("type", {
      is: (type: string) => type === ContactMethodTypes.Email,
      then: (schema) =>
        schema
          .email("Eamil must be a valid email")
          .max(320, "Cannot be more than 320 characters"),
    })
    .when("type", {
      is: (type: string) =>
        type === ContactMethodTypes.MobileNumber ||
        type === ContactMethodTypes.WorkNumber,
      then: (schema) => getValidatedPhoneSchema(schema),
    }),
});

const shliachPersonValidationSchema = yup.object({
  profileImageURL: yup
    .mixed()
    .notOneOf([""], `Profile image ${requiredMessage}`)
    .required(`Profile image ${requiredMessage}`),
  firstName: yup.string().required(`First name ${requiredMessage}`),
  lastName: yup.string().required(`Last name ${requiredMessage}`),
  nickname: yup.string().required(`Name called by ${requiredMessage}`),
  hebrewName: yup.string().required(`Hebrew name ${requiredMessage}`),
  title: yup.string().required(`Title ${requiredMessage}`),
  dob: dateOnlySchema
    .required(`Birthday ${requiredMessage}`)
    .test(
      "valid-birthday",
      "Birthday is invalid",
      (value) => !value || !isNotValidDOB(value),
    ),
  beforeNightfall: nullBooleanSchema.required(
    `Nightfall/Sunset ${requiredMessage}`,
  ),
  contactMethods: yup
    .array(contactMethodValidationSchema)
    .test(
      "primary-email-contact-method-required",
      `One email contact method ${requiredMessage}`,
      (value) =>
        value?.filter(
          (cm) => cm.isPrimary && cm.type === ContactMethodTypes.Email,
        ).length === 1,
    )
    .test(
      "primary-mobile-contact-method-required",
      `One mobile contact method ${requiredMessage}`,
      (value) =>
        value?.filter(
          (cm) => cm.isPrimary && cm.type === ContactMethodTypes.MobileNumber,
        ).length === 1,
    ),
  outreach: outreachValidationSchema,
});
// #endregion

// #region Chabad house
// #region payment provide field dependency management:
const paymentProviderAccountIDValidationDeps = ["publicKey", "secretKey"];
const paymentProviderPublicKeyValidationDeps = [
  "paymentProviderAccountID",
  "secretKey",
];
const paymentProviderSecretKeyValidationDeps = [
  "paymentProviderAccountID",
  "publicKey",
];
const paymentProviderValidationDeps: any = [
  paymentProviderAccountIDValidationDeps,
  paymentProviderPublicKeyValidationDeps,
  paymentProviderSecretKeyValidationDeps,
];
const dependencyInfoProvided = (...params: any[]) =>
  params.filter((p) => p).length > 0;

const paymentProviderValidationSchema = yup.object().shape(
  {
    paymentProviderAccountID: yup
      .string()
      .when(paymentProviderAccountIDValidationDeps, {
        is: dependencyInfoProvided,
        then: (schema) => schema.required(`Account ID key ${requiredMessage}`),
      }),
    publicKey: yup.string().when(paymentProviderPublicKeyValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required(`Publishable key ${requiredMessage}`),
      otherwise: (schema) => schema.nullable(),
    }),
    secretKey: yup.string().when(paymentProviderSecretKeyValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) =>
        schema
          .required(`Secret key ${requiredMessage}`)
          .min(
            20,
            "Secret key is too short (See field tooltip for more details)",
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  },
  paymentProviderValidationDeps,
);

const getUrlProtocolValidationSchema = (description: string) =>
  yup
    .string()
    .test(
      "http-protocol",
      `${description} must contain 'https://'`,
      (value) => {
        if (value) {
          const url = value.toLowerCase();
          return url.includes("http://") || url.includes("https://");
        } else return true;
      },
    )
    .url(`${description} must be a valid url`);

const socialMediaAccountValidationSchema = yup.object({
  url: yup
    .string()
    .url("Social media url must be a valid URL")
    .when("optOut", {
      is: (optOut: boolean) => !optOut,
      then: (schema) =>
        schema
          .notOneOf(
            [
              "https://facebook.com/",
              "https://instagram.com/",
              "https://x.com/",
            ],
            "Social media url must not be default",
          )
          .when("type", {
            is: (type: string) => type === SocialAccountTypes.Facebook,
            then: (schema) =>
              schema.test(
                "facebookUrl",
                "Url must contain facebook.com/",
                (value) => value?.includes("facebook.com/"),
              ),
          })
          .when("type", {
            is: (type: string) => type === SocialAccountTypes.Instagram,
            then: (schema) =>
              schema.test(
                "instagramUrl",
                "Url must contain instagram.com/",
                (value) => value?.includes("instagram.com/"),
              ),
          })
          .when("type", {
            is: (type: string) => type === SocialAccountTypes.X,
            then: (schema) =>
              schema.test(
                "twitterUrl",
                "Url must contain x.com/",
                (value) => value?.includes("x.com/"),
              ),
          })
          .required(`Social media url ${requiredMessage} or opt out`),
      otherwise: (schema) => schema.nullable(),
    }),
  type: yup.string().required(`Type ${requiredMessage}`),
  optOut: nullBooleanSchema.required(`Opt out ${requiredMessage}`),
});
// #endregion

const chabadHousePersonnelValidationSchema = yup.object({
  personID: nullNumberSchema
    .when("isStudent", {
      is: (isStudent: boolean) => isStudent,
      then: (schema) => schema.required(`Student ${requiredMessage}`),
    })
    .nullable(),
  firstName: yup.string().when("isStudent", {
    is: (isStudent: boolean) => !isStudent,
    then: (schema) => schema.required(`First name ${requiredMessage}`),
  }),
  lastName: yup.string().when("isStudent", {
    is: (isStudent: boolean) => !isStudent,
    then: (schema) => schema.required(`Last name ${requiredMessage}`),
  }),
  email: yup.string().when("isStudent", {
    is: (isStudent: boolean) => !isStudent,
    then: (schema) =>
      schema
        .required(`Email ${requiredMessage}`)
        .email("Email must be a valid email"),
  }),
  position: yup.string().required(`Position ${requiredMessage}`),
  expirationDate: dateOnlySchema.nullable(),
});

const chabadHouseValidationSchema = yup.object({
  buildingImageURL: yup
    .mixed()
    .notOneOf([""], `Building image ${requiredMessage}`)
    .required(`Building image ${requiredMessage}`),
  logoURL: yup
    .mixed()
    .notOneOf([""], `Logo image ${requiredMessage}`)
    .required(`Logo image ${requiredMessage}`),
  slug: yup.string().required(`Url friendly name ${requiredMessage}`),
  regionID: nullNumberSchema.required(`Region ${requiredMessage}`),
  websiteURL: getUrlProtocolValidationSchema("Website url").required(
    `Website url ${requiredMessage}`,
  ),
  donateURL: getUrlProtocolValidationSchema("Website donation url")
    .required(`Website donation url ${requiredMessage}`),
  legalName: yup.string().required(`Legal Name ${requiredMessage}`),
  address: yup.object({
    ...addressValidationSchema,
    hideFromPublic: nullBooleanSchema.required(
      `Hide/show publicly ${requiredMessage}`,
    ),
  }),
  paymentProviderAccounts: yup.array(paymentProviderValidationSchema),
  personnel: yup.array(chabadHousePersonnelValidationSchema),
  socialMediaAccounts: yup.array(socialMediaAccountValidationSchema),
  email: contactMethodValidationSchema.required(`Email ${requiredMessage}`),
  phone: contactMethodValidationSchema.required(`Phone ${requiredMessage}`),
  taxID: yup.string(), //Taking out required until we build an opt out .required(`Tax ${requiredMessage}`),
  isRegistered501: nullBooleanSchema.required(
    `Registered as a 501(c)(3) ${requiredMessage}`,
  ),
  campuses: yup
    .array(
      yup.object({
        distance: yup
          .string()
          .required(`Distance to Chabad house ${requiredMessage}`),
        isFullTime: nullBooleanSchema.required(
          `Full/limited service ${requiredMessage}`,
        ),
        isPrimary: nullBooleanSchema,
      }),
    )
    .test(
      "primary-campus-required",
      `Primary campus ${requiredMessage}`,
      (value) => !value?.length || value.some((c) => c.isPrimary),
    ),
});

// #endregion

// #region Campus
const campusValidationSchema = yup.object({
  annualTuition: nullNumberSchema.required(`Tuition ${requiredMessage}`),
  graduatePopulation: nullNumberSchema.when("programLevels", {
    is: (programLevels: string[]) => programLevels.includes(ProgramLevels.Grad),
    then: (schema) => schema.required(`Graduate population ${requiredMessage}`),
    otherwise: (schema) => schema.nullable(),
  }),
  isInstitutionControlPublic: nullBooleanSchema.required(
    `Public/Private ${requiredMessage}`,
  ),
  jewishUndergradPopulation: nullNumberSchema.when("programLevels", {
    is: (programLevels: string[]) =>
      programLevels.includes(ProgramLevels.Undergrad),
    then: (schema) =>
      schema.required(`Jewish undergrad population ${requiredMessage}`),
    otherwise: (schema) => schema.nullable(),
  }),
  jewishGraduatePopulation: nullNumberSchema.when("programLevels", {
    is: (programLevels: string[]) => programLevels.includes(ProgramLevels.Grad),
    then: (schema) =>
      schema.required(`Jewish graduate population ${requiredMessage}`),
    otherwise: (schema) => schema.nullable(),
  }),
  nickname: yup.string().when("nicknameOptOut", {
    is: (optOut: Boolean) => !optOut,
    then: (schema) => schema.required(`Nickname ${requiredMessage} or opt out`),
    otherwise: (schema) => schema.nullable(),
  }),
  nicknameOptOut: nullBooleanSchema.nullable(),
  prestige: yup.string().required(`School type ${requiredMessage}`),
  programLevels: yup
    .array(yup.string())
    .min(1, `Demographics ${requiredMessage}`),
  schoolCalendarType: yup
    .string()
    .notOneOf(["Unknown"], `School calendar ${requiredMessage}`)
    .required(`School calendar ${requiredMessage}`),
  studentResidencyType: yup
    .string()
    .notOneOf(["Unknown"], `Student residency ${requiredMessage}`)
    .required(`Student residency ${requiredMessage}`),
  studyAreas: yup.array(yup.string()).min(1, `Study areas ${requiredMessage}`),
  undergradPopulation: nullNumberSchema.when("programLevels", {
    is: (programLevels: string[]) =>
      programLevels.includes(ProgramLevels.Undergrad),
    then: (schema) =>
      schema.required(`Undergrad population ${requiredMessage}`),
    otherwise: (schema) => schema.nullable(),
  }),
  chabadStudentGroupStatus: yup
    .string()
    .required(`Chabad student group status ${requiredMessage}`),
  shliachChaplainStatus: yup
    .string()
    .required(`Shliach chaplain status ${requiredMessage}`),
  shluchaChaplainStatus: yup
    .string()
    .required(`Shlucha chaplain status ${requiredMessage}`),
  studentGroups: yup
    .array(yup.string())
    .min(1, `Student groups selection ${requiredMessage}`),
  studentResources: yup
    .array()
    .test(
      "required-student-resources",
      `Student resources selection ${requiredMessage}`,
      function (value) {
        const allStudentResources = this.options.context?.allStudentResources;
        if (!allStudentResources?.length) return true;

        return !allStudentResources.some(
          (r: any) => !value?.some((v) => v.studentResourceID === r.id),
        );
      },
    ),
});
// #endregion

export const validationSchema = yup.object({
  campuses: yup.array(campusValidationSchema),
  family: familyValidationSchema,
  person: shliachPersonValidationSchema,
  spouse: shliachPersonValidationSchema.nullable(),
  chabadHouses: yup.array(chabadHouseValidationSchema),
});

// #endregion

// #region Utils

/**
 * Replace values within an object
 */
function replaceProps(obj: Object, toReplace: any, replaceWith: any) {
  const newObj = clonedeep(obj);
  Object.keys(newObj).forEach((key) => {
    const value = newObj[key];
    if (value === toReplace) {
      newObj[key] = replaceWith;
    } else if (value) {
      if (value instanceof Array) {
        value.forEach((rec, i) => {
          if (rec instanceof Object && !(value instanceof Date)) {
            newObj[key][i] = replaceProps(rec, toReplace, replaceWith);
          }
        });
      } else if (
        value instanceof Object &&
        !(value instanceof Date) &&
        !(value instanceof File)
      ) {
        newObj[key] = replaceProps(value, toReplace, replaceWith);
      }
    }
  });
  return newObj;
}

function setDefaultForSocialAccountType(type: string, ch: ChabadHouse) {
  if (!ch.socialMediaAccounts.some((a) => a.type === type)) {
    ch.socialMediaAccounts.push({
      chabadHouseID: ch.id,
      url: "",
      type,
      optOut: false,
    });
  }
}

function setDefaultPersonOutreach(person: ShliachPerson) {
  if (!person.outreach) {
    person.outreach = {
      demographics: [],
      category: "",
      shliachID: person.shliachID,
    };
  }
}

export function formatProfileForForm(values: ShliachProfile) {
  const formattedValues = replaceProps(values, null, "") as ShliachProfile;
  const { chabadHouses, person, spouse } = formattedValues;

  // default nickName to firstName value
  if (person) {
    if (!person.nickname) {
      person.nickname = person.firstName ?? "";
    }
    setDefaultContactMethods(person.contactMethods);
    setDefaultPersonOutreach(person);
  }
  if (spouse) {
    if (!spouse.nickname) {
      spouse.nickname = spouse.firstName ?? "";
    }
    setDefaultContactMethods(spouse.contactMethods);
    setDefaultPersonOutreach(spouse);
  }

  chabadHouses.forEach((ch) => {
    //make sure we have each social media type in the array so we can require one of each
    setDefaultForSocialAccountType(SocialAccountTypes.Facebook, ch);
    setDefaultForSocialAccountType(SocialAccountTypes.Instagram, ch);
    setDefaultForSocialAccountType(SocialAccountTypes.X, ch);

    if (!ch.email) {
      ch.email = {
        type: ContactMethodTypes.Email,
        value: "",
        isPrimary: true,
      };
    }
    if (!ch.phone) {
      ch.phone = {
        type: ContactMethodTypes.WorkNumber,
        value: "",
        phoneExtension: "",
        isPrimary: true,
      };
    }
  });
  return formattedValues;
}
function setDefaultContactMethods(contactMethods: ContactMethod[]) {
  //make sure we have each contect method type (email and phone) in the array so we can default those values and require them
  const email = contactMethods.find(
    (cm) => cm.type === ContactMethodTypes.Email,
  );
  if (!email) {
    contactMethods.push({
      type: ContactMethodTypes.Email,
      value: "",
      isPrimary: true,
    });
  }
  const phone = contactMethods.find(
    (cm) => cm.type === ContactMethodTypes.MobileNumber,
  );
  if (!phone) {
    contactMethods.push({
      type: ContactMethodTypes.MobileNumber,
      value: "",
      isPrimary: true,
    });
  }
}

export async function formatProfileForSubmission(
  values: ShliachProfile,
  dispatch: Dispatch<AnyAction>,
) {
  const formattedValues = replaceProps(values, null, "") as ShliachProfile;
  await uploadProfileImages(formattedValues, dispatch);

  return formattedValues;
}

async function uploadProfileImages(
  profile: ShliachProfile,
  dispatch: Dispatch<AnyAction>,
) {
  //TODO: make uploads asynchronous
  const { uploadFile } = SysActions;
  const { person, spouse, chabadHouses } = profile;
  if (person) {
    const personImage = person.profileImageURL;
    if (typeof personImage !== "string" && personImage?.file) {
      person.profileImageURL = await dispatch(
        uploadFile(personImage.file, "profile", false) as any,
      );
    }
  }
  if (spouse) {
    const spouseImage = spouse.profileImageURL;
    if (typeof spouseImage !== "string" && spouseImage?.file) {
      spouse.profileImageURL = await dispatch(
        uploadFile(spouseImage.file, "profile", false) as any,
      );
    }
  }
  await Promise.all(
    chabadHouses.map((ch) => uploadChabadHouseImages(ch, dispatch)),
  );
}

async function uploadChabadHouseImages(
  chabadHouse: ChabadHouse,
  dispatch: Dispatch<AnyAction>,
) {
  const { uploadFile } = SysActions;
  const buildingImage = chabadHouse.buildingImageURL;
  const logoImage = chabadHouse.logoURL;
  if (typeof buildingImage !== "string" && buildingImage?.file) {
    chabadHouse.buildingImageURL = await dispatch(
      uploadFile(buildingImage.file, "house", false) as any,
    );
  }
  if (typeof logoImage !== "string" && logoImage?.file) {
    chabadHouse.logoURL = await dispatch(
      uploadFile(logoImage.file, "house_logo", false) as any,
    );
  }
}

// #endregion
