import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { ProgramLevels } from "../../ProfileFormHelpers";
import {
  FormFieldWatch,
  FormSelectField,
  FormTextField,
  FormCheckboxField,
  getSetFieldValueOptions,
  FormToggleField,
  FormNumberField,
} from "../../../../../components/react-hook-form";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormGridQuarterRowItemStyled,
  ProfileFormGridFullRowItemStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";
import { CampusInfoProps } from "./CampusInfo";
import { useMobile } from "../../../../../themes";

export const CampusGeneralInfo = React.memo(
  /**
   *
   */
  function CampusInfo(props: CampusInfoProps) {
    const { campusIndex, form, initialFormValues } = props;

    const initialCampusProfile = initialFormValues.campuses[campusIndex];
    const campusFieldsAccessor = `campuses.${campusIndex}`;

    const programLevels = useSelector(SystemSelectors.programLevels);
    const schoolTypes = useSelector(SystemSelectors.prestigeTypes);
    const studyAreas = useSelector(SystemSelectors.studyAreas);

    const isMobile = useMobile();

    return (
      <>
        <Typography variant="h6">
          School info - {initialCampusProfile.name}
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "0px", marginBottom: "50px" }}
        >
          <ProfileFormGridQuarterRowItemStyled>
            <FormFieldWatch name={`campuses.${campusIndex}.nicknameOptOut`}>
              {(nicknameOptOut) => (
                <FormTextField
                  label="College nickname/initial"
                  name={`${campusFieldsAccessor}.nickname`}
                  disabled={!!nicknameOptOut}
                />
              )}
            </FormFieldWatch>
          </ProfileFormGridQuarterRowItemStyled>

          <ProfileFormGridItemStyled
            sm={1}
            style={isMobile ? { paddingTop: 0 } : { marginTop: 21 }}
          >
            <FormCheckboxField
              label={isMobile ? "No nickname/initial" : "N/A"}
              name={`${campusFieldsAccessor}.nicknameOptOut`}
              onValueChange={(value) => {
                if (value) {
                  form.setValue(
                    `campuses.${campusIndex}.nickname`,
                    "",
                    getSetFieldValueOptions(form),
                  );
                }
              }}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormSelectField
              label="School Type"
              name={`${campusFieldsAccessor}.prestige`}
              options={schoolTypes.map((t: any) => {
                return { id: t.enumValue, name: t.displayValue };
              })}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormToggleField
              label="Student residency"
              name={`${campusFieldsAccessor}.studentResidencyType`}
              options={[
                { id: "Commuter", name: "Commuter" },
                { id: "Residential", name: "Residential" },
              ]}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormToggleField
              label="School Calendar"
              name={`${campusFieldsAccessor}.schoolCalendarType`}
              options={[
                { id: "Quarterly", name: "Quarterly" },
                { id: "Semester", name: "Semester" },
                //TODO: add this in at a later point after discovery { id: "Unknown", name: "Other" },
              ]}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormToggleField
              label="Public/Private"
              name={`${campusFieldsAccessor}.isInstitutionControlPublic`}
              options={[
                { id: true, name: "Public" },
                { id: false, name: "Private" },
              ]}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormSelectField
              label="Demographics"
              multiple={true}
              name={`${campusFieldsAccessor}.programLevels`}
              onValueChange={(values: string[]) => {
                if (!values.includes(ProgramLevels.Undergrad)) {
                  form.setValue(
                    `campuses.${campusIndex}.undergradPopulation`,
                    null,
                    getSetFieldValueOptions(form),
                  );
                  form.setValue(
                    `campuses.${campusIndex}.jewishUndergradPopulation`,
                    null,
                    getSetFieldValueOptions(form),
                  );
                }
                if (!values.includes(ProgramLevels.Grad)) {
                  form.setValue(
                    `campuses.${campusIndex}.graduatePopulation`,
                    null,
                    getSetFieldValueOptions(form),
                  );
                  form.setValue(
                    `campuses.${campusIndex}.jewishGraduatePopulation`,
                    null,
                    getSetFieldValueOptions(form),
                  );
                }
              }}
              options={programLevels.map((d: any) => ({
                id: d.enumValue,
                name: d.enumValue,
              }))}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridFullRowItemStyled>
            <FormSelectField
              label="Area of study"
              multiple={true}
              name={`${campusFieldsAccessor}.studyAreas`}
              options={studyAreas.map((d: any) => ({
                id: d.enumValue,
                name: d.displayValue,
              }))}
            />
          </ProfileFormGridFullRowItemStyled>
          {/* TODO:in later phase handle other currencies */}
          <ProfileFormGridItemStyled>
            <FormNumberField
              isCurrency={true}
              label="Tuition"
              name={`${campusFieldsAccessor}.annualTuition`}
            />
          </ProfileFormGridItemStyled>
          {/* TODO when hook up api, need to show additional tuition fields */}
          {/* <ProfileFormGridItemStyled>
              <FormNumberField
              isCurrency={true}
              label="In state tuition"
              name={`${campusFieldsAccessor}.tuitionInState`}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormNumberField
              isCurrency={true}
              label="Out of state tuition"
              name={`${campusFieldsAccessor}.tuitionOutOfState`}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
                 <FormNumberField
              isCurrency={true}
              label="International tuition"
              name={`${campusFieldsAccessor}.tuitionInternational`}
            />
          </ProfileFormGridItemStyled> */}
        </ProfileFormGridStyled>
      </>
    );
  },
);
