import React, { forwardRef } from "react";
import {
  FormLabelProps,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core";

export type TextInputProps = {
  inputPrefix?: string;
  labelProps?: FormLabelProps;
} & TextFieldProps;

export const TextInput = React.memo(
  forwardRef(
    /**
     *
     */
    function TextInput(
      {
        fullWidth = true,
        id,
        inputPrefix,
        InputProps,
        label,
        name,
        size = "small",
        variant = "outlined",
        ...passProps
      }: TextInputProps,
      ref: React.Ref<any> | null,
    ) {
      return (
        <>
          {label && (
            <InputLabel error={passProps.error} htmlFor={id || name}>
              {label}
            </InputLabel>
          )}
          <TextField
            fullWidth={fullWidth}
            id={id || name}
            name={name}
            ref={ref}
            size={size}
            variant={variant as any}
            InputProps={{
              ...(variant !== "outlined" && { disableUnderline: true }),
              startAdornment: inputPrefix ? (
                <Typography color="primary" style={{ marginRight: "8px" }}>
                  {inputPrefix}
                </Typography>
              ) : null,
              ...InputProps,
            }}
            {...passProps}
          />
        </>
      );
    },
  ),
);
