import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import {
  FormFieldWatch,
  FormGoogleAddressField,
  FormSelectField,
  FormTextField,
  FormToggleField,
  FormCheckboxField,
  getSetFieldValueOptions,
} from "../../../../../components/react-hook-form";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormGridHalfRowItemStyled,
  ProfileFormGridQuarterRowItemStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";
import { ChabadHouseAddress } from "../../ProfileFormHelpers";
import { useMobile } from "../../../../../themes";

export const ChabadHouseAddressInfo = React.memo(
  /**
   *
   */
  function ChabadHouseAddressInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, form, initialFormValues } = props;
    const chabadHouseAddressFieldsAccessor = `chabadHouses.${chabadHouseIndex}.address`;

    const countries = useSelector(SystemSelectors.countries);

    const [
      chabadHouseAddressSameAsPersonal,
      setChabadHouseAddressSameAsPersonal,
    ] = useState(false);

    const isMobile = useMobile();
    return (
      <Box>
        <Typography
          variant="subtitle2"
          style={{
            display: isMobile ? undefined : "flex",
            alignItems: "center",
          }}
        >
          Address information
          {/* TODO: add the checkbox back in phase 2 */}
          {/* <FormCheckboxField
            label="Same as personal address"
            name="chabadHouseAddressSameCheckbox"
            value={chabadHouseAddressSameAsPersonal}
            style={{ paddingLeft: isMobile ? undefined : 32 }}
            onValueChange={(val) => {
              setChabadHouseAddressSameAsPersonal(val);
              if (val) {
                if (initialFormValues.family?.address) {
                  form.setValue(
                    `chabadHouses.${chabadHouseIndex}.address`,
                    {
                      ...initialFormValues.family.address,
                      hideFromPublic:
                        form.getValues().chabadHouses[chabadHouseIndex].address
                          ?.hideFromPublic || false,
                    },
                    getSetFieldValueOptions(form),
                  );
                }
              } else {
                form.setValue(
                  `chabadHouses.${chabadHouseIndex}.address`,
                  {
                    address1: "",
                    address2: "",
                    city: "",
                    country: "",
                    state: "",
                    zip: "",
                    hideFromPublic:
                      form.getValues().chabadHouses[chabadHouseIndex].address
                        ?.hideFromPublic || false,
                  },
                  getSetFieldValueOptions(form),
                );
              }
            }}
          /> */}
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "24px", marginBottom: "42px" }}
        >
          <ProfileFormGridHalfRowItemStyled>
            <FormGoogleAddressField
              name={`${chabadHouseAddressFieldsAccessor}.address1`}
              label="Address"
              onSelect={(address) =>
                form.setValue(
                  `chabadHouses.${chabadHouseIndex}.address`,
                  address as ChabadHouseAddress,
                  getSetFieldValueOptions(form),
                )
              }
            />
          </ProfileFormGridHalfRowItemStyled>
          <ProfileFormGridHalfRowItemStyled>
            <FormTextField
              name={`${chabadHouseAddressFieldsAccessor}.address2`}
              label="Address line 2"
            />
          </ProfileFormGridHalfRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormSelectField
              name={`${chabadHouseAddressFieldsAccessor}.country`}
              label="Country"
              options={countries.map((c: any) => ({
                id: c.name,
                name: c.name,
              }))}
              onValueChange={() =>
                form.setValue(
                  `chabadHouses.${chabadHouseIndex}.address.state`,
                  "",
                  getSetFieldValueOptions(form),
                )
              }
            />
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormTextField
              name={`${chabadHouseAddressFieldsAccessor}.city`}
              label="City"
            />
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormFieldWatch
              name={`${chabadHouseAddressFieldsAccessor}.country`}
            >
              {(country) => {
                const useStatesSelect =
                  country === "USA" ||
                  country === "Canada" ||
                  country === "Mexico";

                if (useStatesSelect) {
                  const states =
                    countries.find((c: any) => c.name === country)?.states ||
                    [];
                  return (
                    <FormSelectField
                      name={`${chabadHouseAddressFieldsAccessor}.state`}
                      label="State"
                      options={states.map((s: any) => ({
                        id: s.name,
                        name: s.name,
                      }))}
                    />
                  );
                }

                return (
                  <FormTextField
                    name={`${chabadHouseAddressFieldsAccessor}.state`}
                    label="State"
                  />
                );
              }}
            </FormFieldWatch>
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormTextField
              name={`${chabadHouseAddressFieldsAccessor}.zip`}
              label="Zip"
            />
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridItemStyled sm={5}>
            <FormToggleField
              color="secondary"
              label="Address visibility"
              name={`${chabadHouseAddressFieldsAccessor}.hideFromPublic`}
              options={[
                {
                  id: false,
                  name: "Show publicly",
                },
                {
                  id: true,
                  name: "Hide from public",
                },
              ]}
            />
          </ProfileFormGridItemStyled>
        </ProfileFormGridStyled>
      </Box>
    );
  },
);
