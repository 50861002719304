import React, { useCallback, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { Genders, ShliachProfile } from "../ProfileFormHelpers";
import {
  ProfileFormMenuStyled,
  ProfileMenuItemButtonStyled,
} from "../ProfileForm.styles";
import {
  getProfileMenuItemId,
  getProfileSectionId,
  profileMenuItemClassName,
} from "./ProfileFormMenuHelpers";
import { useMobile } from "../../../../themes";

interface MenuItemConfig {
  header?: string | null;
  id: string;
  title: string;
}

interface ProfileFormMenuProps {
  initialFormValues: ShliachProfile;
}

export const ProfileFormMenu = React.memo(
  /**
   *
   */

  function ProfileFormMenu({ initialFormValues }: ProfileFormMenuProps) {
    const isMobile = useMobile();

    const menuItems = useMemo(() => {
      const menuItems: MenuItemConfig[] = [];

      if (!initialFormValues) return menuItems;

      menuItems.push(
        {
          id: getProfileSectionId("person"),
          title:
            initialFormValues.person?.gender === Genders.Female
              ? "Shlucha"
              : "Shliach",
        },
        {
          id: getProfileSectionId("spouse"),
          title:
            initialFormValues.spouse?.gender === Genders.Female
              ? "Shlucha"
              : "Shliach",
        },
        {
          id: getProfileSectionId("family"),
          title: "Family",
        },
      );

      initialFormValues.chabadHouses.forEach((chabadHouse, index) => {
        if (isMobile) {
          menuItems.push({
            id: getProfileSectionId("chabadHouse", index),
            title: chabadHouse.name || "Chabad house",
          });
        } else {
          menuItems.push(
            {
              id: getProfileSectionId("chabadHouseGeneral", index),
              title: "Chabad House info",
              header: chabadHouse.name,
            },
            {
              id: getProfileSectionId("chabadHousePersonnel", index),
              title: "Personnel",
            },
            {
              id: getProfileSectionId("chabadHouseFinancial", index),
              title: "Financial Info",
            },
          );
        }
      });

      initialFormValues.campuses.forEach((campus, index) => {
        if (isMobile) {
          menuItems.push({
            id: getProfileSectionId("campus", index),
            title: campus.name || "School",
          });
        } else {
          menuItems.push(
            {
              id: getProfileSectionId("campusGeneral", index),
              title: "School info",
              header: campus.name,
            },
            {
              id: getProfileSectionId("campusJewishStudentLife", index),
              title: "Jewish life",
            },
          );
        }
      });

      return menuItems;
    }, [initialFormValues, isMobile]);

    return (
      <ProfileFormMenuStyled id="profile-form-menu">
        {menuItems.map((item) => (
          <MenuItem {...item} key={item.id} />
        ))}
      </ProfileFormMenuStyled>
    );
  },
);

function MenuItem({ header, id, title }: MenuItemConfig) {
  const isMobile = useMobile();

  const onClick = useCallback(() => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  }, [id]);

  return (
    <>
      {header && (
        <Typography color="textSecondary" variant="overline">
          {header}
        </Typography>
      )}
      <ProfileMenuItemButtonStyled
        className={profileMenuItemClassName}
        fullWidth={!isMobile}
        id={getProfileMenuItemId(id)}
        onClick={onClick}
        variant="text"
      >
        {title}
      </ProfileMenuItemButtonStyled>
    </>
  );
}
